import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import firebaseApp from '../firebase/init';
import { getDatabase, ref, get } from 'firebase/database';
import { formatDate, formatNumber } from '../helpers/utils';
const db = getDatabase(firebaseApp);

function InvoiceDetail() {
  const [invoice, setInvoice] = useState();
  const params = useParams();
  const shouldReadData = useRef(true);

  useEffect(() => {
    if (shouldReadData.current) {
      const id = params.id;
      const dbRef = ref(db, '/invoices/' + id);
      get(dbRef).then((snapshot) => {
        const data = snapshot.val();
        setInvoice(data);
      });

      shouldReadData.current = false;
    }
  }, [params.id]);
  // Fungsi untuk mengkonversi angka ke terbilang bahasa Indonesia
  const terbilang = (number) => {
    const bilangan = [
      "", "Satu", "Dua", "Tiga", "Empat", "Lima", "Enam", "Tujuh", "Delapan", "Sembilan", "Sepuluh", "Sebelas"
    ];

    const getPecahan = (n) => {
      if (n < 12) return bilangan[n];
      if (n < 20) return bilangan[n - 10] + " Belas";
      if (n < 100) return bilangan[Math.floor(n / 10)] + " Puluh " + bilangan[n % 10];
      if (n < 200) return "Seratus " + getPecahan(n % 100);
      if (n < 1000) return bilangan[Math.floor(n / 100)] + " Ratus " + getPecahan(n % 100);
      if (n < 2000) return "seribu " + getPecahan(n % 1000);
      if (n < 1000000) return getPecahan(Math.floor(n / 1000)) + " Ribu " + getPecahan(n % 1000);
      if (n < 1000000000) return getPecahan(Math.floor(n / 1000000)) + " Juta " + getPecahan(n % 1000000);
      return getPecahan(Math.floor(n / 1000000000)) + " Milyar " + getPecahan(n % 1000000000);
    };

    const cleanNumber = Math.floor(Math.abs(number));
    if (cleanNumber === 0) return "nol";
    return getPecahan(cleanNumber);
  };

  const PPN = 11; // Ganti PPN dengan nilai yang sesuai

  function formatPercent(number) {
    return `${number}%`;
  }

  if (!invoice) {
    return <>Invoice Not found</>;
  }
  return (
    <Container>
      <Row className="justify-content-center mt-3">
        <Col md={8} className="mx-auto">
          <Row style={{ height: '200px' }}>
            <Col md={12} xs={12}>
              <img src="/kf-logo.jpeg" alt="kimia farma logo" width="320" />
            </Col>

            <Col
              md={{ span: 10, offset: 2 }}
              xs={{ span: 10, offset: 2 }}
              className="d-flex "
              style={{
                flexDirection: 'column',
                justifyContent: 'flex-end',
                textAlign: 'right',
              }}
            >
              <strong>
                PT KIMIA FARMA DIAGNOSTIKA UNIT BISNIS JAKARTA 2 <br />
                Jl. Garuda No.47, RW.4, Gn. Sahari Sel, Kota Jakarta Pusat 10610
              </strong>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={6} className="p-1">
              <strong>INVOICE / OFFICIAL RECEIPT</strong>
              <section
                className="box-border--solid p-2 box-invoice"
                style={{ minHeight: '120px' }}
              >
                To: <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong>{invoice.company}</strong>
              </section>
            </Col>
            <Col md={6} xs={6} className="mt-4 p-1">
              <section
                className="box-border--solid p-2"
                style={{ minHeight: '120px' }}
              >
                <table>
                  <tbody style={{ fontSize: '14px' }}>
                    <tr>
                      <td>Jatuh tempo</td>
                      <td>:</td>
                      <td>{formatDate(invoice.dueDate)}</td>
                    </tr>
                    <tr>
                      <td>Statement No</td>
                      <td>:</td>
                      <td>{invoice.noInvoice}</td>
                    </tr>
                    <tr>
                      <td>Pembuatan</td>
                      <td>:</td>
                      <td>{formatDate(invoice.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>Periode</td>
                      <td>:</td>
                      <td>{invoice.period}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="p-1">
              <section className=" mt-3">
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr style={{ border: '1px solid #000', background: 'lightgray' }} className='text-center p-2'>
                      <th style={{width: '70%' }} className='p-2'>Keterangan</th>
                      <th style={{ width: '30%'}} className='p-2'>Total</th>
                    </tr>
                  </thead>
                  <tbody className='border border-1 border-dark'>
                    <tr>
                      <td className='p-2' style={{borderRight: '1px solid #000'}}>{`${invoice?.branch} - ${invoice?.company}`}</td>
                      <td className='p-2 text-right'>{formatNumber(invoice.totalBill)}</td>
                    </tr>
                    <tr>
                      <td className='p-2' style={{borderRight: '1px solid #000'}}>Total</td>
                      <td className='p-2 text-right'>{formatNumber(invoice.totalBill)}</td>
                    </tr>
                    <tr>
                      <td className='p-2' style={{borderRight: '1px solid #000'}}>PPN {formatPercent(PPN)} (dibebaskan)</td>
                      <td className='p-2 text-right'>{formatNumber(invoice.totalBill * (PPN/100))}</td>
                    </tr>
                    <tr>
                      <td className='p-2' style={{borderRight: '1px solid #000'}}>Grand Total</td>
                      <td className='p-2 text-right'>{formatNumber(invoice.totalBill)}</td>
                    </tr>
                    <tr>
                      <td className='p-2' style={{borderRight: '1px solid #000'}}>Terbilang:</td>
                    </tr>
                    <tr>
                    <td className='p-2 text-left'  style={{borderRight: '1px solid #000'}}>{terbilang(invoice.totalBill)} Rupiah</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </Col>
          </Row>
          <Row>
            <Col md={8} xs={8} className="p-1">
              <section className="box-border--solid mt-3 p-2 h-300">
                <p>
                  <strong>
                    Note: <br />
                    PT. Kimia Farma Diagnostika <br />
                    Bank Mandiri KCP Jakarta Juanda <br />
                    IDR A/C. No. 119-000-6108-409
                  </strong>
                </p>
                <p>
                  Apabila telah dilakukan pembayaran mohon konfirmasi dengan
                  mengirimkan bukti transfer pada Finance &amp; Accounting
                  Department melalui
                </p>
                <ul>
                  <li>
                    <strong>
                      Phone (WA) :089614857680 ARIF/ 08118740034 INA FITRI
                    </strong>
                  </li>
                  <li>
                    <strong>Email :piutangbmjakarta2@gmail.com</strong>
                  </li>
                </ul>
              </section>
            </Col>
            <Col md={4} xs={4} className="m-0 p-1">
              <section
                className="box-border--solid mt-3 h-300 text-center"
                style={{
                  alignItems: 'flex-end',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <p className="text-center">
                  <strong>(Heru Sudomo)</strong>
                  <br />
                  <strong>Bisnis Manager Jakarta 2</strong>
                </p>
              </section>
            </Col>
          </Row>
          <Row>
            <Col
              md={{ offset: 10, span: 2 }}
              className="my-3 text-right d-print-none "
            >
              <Button color="primary" onClick={() => window.print()}>
                Cetak
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default InvoiceDetail;
